export default function KofiIcon() {
  // SVG Icon for https://ko-fi.com
  return (
    <svg
      className="shadow-md rounded-full"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" transform="matrix(1.9656019 0 0 1.9656019 0 -.0000006)">
        <circle cx="16.280001" cy="16.280001" fill="#29abe0" r="16.280001" />
        <path
          d="m22.257931 8.8h1.607617c3.100776 0 5.614452 2.513676 5.614452 5.614452v.330015c0 3.100776-2.513676 5.614452-5.614452 5.614452h-1.607617v1.689795c0 1.431128-1.160158 2.591286-2.591286 2.591286h-12.2353593c-1.4311276 0-2.5912857-1.160158-2.5912857-2.591286v-11.953071c0-.7155639.5800791-1.295643 1.2956429-1.295643zm0 2.996757v5.565405h1.465573c1.536844 0 2.782703-1.245858 2.782703-2.782702 0-1.536845-1.245859-2.782703-2.782703-2.782703z"
          fill="#fff"
        />
      </g>
      <path
        d="m8.36 5.2768458c.4002942-1.1712306 1.3489414-1.7568458 2.845941-1.7568458 2.2455 0 3.077965 2.7936503 1.900934 4.62-.784687 1.2175665-2.366979 2.757567-4.746875 4.62-2.3798961-1.862433-3.9621877-3.4024335-4.746875-4.62-1.1770309-1.8263497-.3445663-4.62 1.9009337-4.62 1.4969999 0 2.4456471.5856152 2.8459413 1.7568458z"
        fill="#ff5e5b"
        transform="matrix(1.9656019 0 0 1.9656019 9.513513196 17.29729612)"
      />
    </svg>
  );
}
