export default function LeftArrowIcon() {
  // Triangle icons from https://svgbox.net/iconset/octicons
  // Whitespace removal from https://svgcrop.com/
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="8.68 5.68 6.82 12.62"
      className="static opacity-100"
    >
      <path d="M8.854 11.646l5.792-5.792a.5.5 0 01.854.353v11.586a.5.5 0 01-.854.353l-5.792-5.792a.5.5 0 010-.708z"></path>
    </svg>
  );
}
