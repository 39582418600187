export const maxCharacterLimit = 255;
export const minCharacterLimit = 1;

export const maxItemLimitOnList = 500;

export const maxListsPerUser = 100;
export const maxNotesPerItem = 50;

// TODO: Implement sub notes :)
// export const maxSubNotesPerNote = 50;
